.menu-toggler {
  padding: 0;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus {
    outline: 0;
  }

  &:focus,
  &:hover {
    color: #ddd;
  }
}

.sidebar-mask {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100vh;
  cursor: pointer;
  background: #212121;
  opacity: .5;

  &.visible {
    @media screen and (max-width: $media-breakpoint-sm - 1px) {
      display: block;
      opacity: .5;
      transition: opacity .3s;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: -$sidebar-width;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  width: calc(100% - 64px);
  max-width: $sidebar-width;
  height: 100vh;
  padding: 0 ($spacer * 2) ($spacer * 2);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $dark-color;
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
  transition: left .3s;

  &.open {
    left: 0;
    visibility: visible;
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    left: 0;
    padding: $spacer * 2;
    visibility: visible;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  padding: $spacer 0;
  margin: auto 0;
}

.nav-link {
  position: relative;
  height: $nav-link-height;
  font-size: .8rem;
  font-weight: 700;
  line-height: $nav-link-height;
  color: transparentize(#fff, .4);
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 1;
  transition: opacity .15s ease, transform .75s ease;
  transform: translateY(0);

  &.active {
    color: #fff;

    &::after {
      max-width: 100%;
    }
  }

  & + .nav-link {
    margin-top: 1rem;
  }

  &:focus,
  &:hover {
    color: transparentize(#fff, .6);
    text-decoration: none;
    outline: 0;

    &::after {
      max-width: 100%;
    }
  }

  &::after,
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2.5px;
    content: "";
    background-color: transparentize($primary-color, .7);
    border-radius: .2rem;
  }

  &::after {
    max-width: 0;
    background-image: linear-gradient(90deg,#0e2933,#eee);
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    .loading & {
      opacity: 0;
      transform: translateY(1rem);
    }

    // Set different transition delay times to each link
    $transition-delays: .45 .65 .85 1.05 1.25;
    @each $transition-delay in $transition-delays {
      $index: index($transition-delays, $transition-delay);
      &:nth-child(#{$index}) {
        transition-delay: #{$transition-delay}s;
      }
    }
  }
}

.social {
  display: flex;
  align-self: flex-end;
  padding: 0;
  margin: 0;
  list-style: none;
}

.social-item {
  & + .social-item {
    margin-left: 1rem;
  }
}

.social-link {
  display: block;
  color: transparentize(#fff, .4);

  &:focus,
  &:hover {
    color: $accent-color;
    text-decoration: none;
    outline: 0;
  }
}
