@import "variables";

@keyframes animateLinesX {
  0% {
    transform: translate(0, 0, 0);
  }

  50% {
    transform: translate(100%, 0, 0);
  }

  100% {
    transform: translate(0, 0, 0);
  }
}

@keyframes animateLinesY {
  0% {
    transform: translate(0, 0, 0);
  }

  50% {
    transform: translate(0, 20%, 0);
  }

  100% {
    transform: translate(0, 0, 0);
  }
}

.hero {
  position: relative;
  display: flex;
  min-height: calc(100vh - #{$header-height});
  padding: ($spacer * 2) 0 $spacer;
  overflow-y: hidden;
  color: #fff;
  background-color: $primary-color;

  line {
    stroke: transparentize(#fff, .9);
    stroke-width: 1px;
    transform: translate(0, 0, 0);
    will-change: transform;

    .ready & {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        animation: animateLinesX 5s;
        animation-delay: 2s;
      }
    }
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    min-height: 100vh;
  }
}

.hero-lines {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-intro {
  margin: auto 0;

  @media screen and (min-width: $media-breakpoint-md) {
    max-width: 75%;
  }
}

.hero-title {
  margin-bottom: $spacer * 2;
  font-size: 2rem;
  font-weight: 700;
  opacity: 1;
  transition: transform .75s, opacity .3s;
  transition-delay: .45s;
  transform: translateY(0);

  .loading & {
    opacity: 0;
    transform: translateY(-120%);
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    font-size: 3rem;
  }
}

.hero-strap {
  margin-bottom: $spacer * 2;
  font-size: 1.2rem;
  opacity: 1;
  transition: transform .75s, opacity .3s;
  transition-delay: .45s;
  transform: translateY(0);

  .loading & {
    opacity: 0;
    transform: translateY(120%);
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    font-size: 1.3rem;
  }
}
