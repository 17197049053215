.brand {
  display: flex;
  align-items: center;
  margin-right: auto;
  color: #fff;
  border: 1px solid transparent;
  outline: 0;

  &:focus,
  &:hover {
    color: $accent-color;
    text-decoration: none;
  }

  &:focus {
    border-color: $accent-color;
  }

  .sidebar & {
    width: 100%;
    height: $header-height;
    margin: 0;
  }
}

.brand-logo {
  margin-right: .5rem;
}

.brand-description {
  margin-left: auto;
  text-align: right;
}

.brand-name {
  margin: 0;
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
}

.brand-title {
  margin: 0;
  font-size: .7rem;
  text-transform: uppercase;
}

.brand-figure-wrapper {
  margin-bottom: 3rem;

  @media screen and (min-width: $media-breakpoint-md) {
    float: right;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

.brand-figure {
  position: relative;
  max-width: 300px;

  &::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: 5px;
    box-shadow: inset 0 0 0 10px transparentize($primary-color, .6), 0 1px 2px transparentize($primary-color, .7);
  }
}

.brand-avatar {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: 100%;
  border-radius: 5px;
}

.brand-address {
  padding: $spacer;
  color: #fff;
  background-color: $primary-color;
  border-radius: 3px;

  strong {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}

.brand-map-wrapper {
  margin-top: 3rem;
}

.brand-map {
  min-height: 350px;
}
