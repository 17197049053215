.container {
  position: relative;
  width: 100%;
  max-width: $container-max-width;
  padding: 0 $spacer;
  margin: 0 auto;

  main & {
    @media screen and (min-width: $media-breakpoint-md) {
      padding: 0 ($spacer * 2);
    }
  }

  header & {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .hero & {
    display: flex;
    flex-direction: column;
  }
}

.row {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -1rem;

  @media screen and (min-width: $media-breakpoint-md) {
    flex-direction: row;
  }
}

.column {
  position: relative;
  width: 100%;
  padding: 0 1rem;
}

.column-half {
  @media screen and (min-width: $media-breakpoint-md) {
    max-width: 50%;
  }
}

.column-eight {
  @media screen and (min-width: $media-breakpoint-md) {
    max-width: 8% * 100 / 12;
  }
}

.column-four {
  @media screen and (min-width: $media-breakpoint-md) {
    max-width: 4% * 100 / 12;
  }
}
