// Colors
// -----------------------------------------------------------------------------

$primary-color:#0e2933;
$accent-color: #d72e63;
$dark-color: #0e2933;
$link-color: $accent-color;
$body-color: #444;


// Fonts
// -----------------------------------------------------------------------------

$base-font-family: "Work Sans", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Layout
// -----------------------------------------------------------------------------

$container-max-width: 1030px;
$header-height: 64px;
$sidebar-width: 330px;
$spacer: 1.25rem;


// Nav
// -----------------------------------------------------------------------------

$nav-link-height: 40px;


// Media breakpoints
// -----------------------------------------------------------------------------
$media-breakpoint-lg: 1024px;
$media-breakpoint-md: 992px;
$media-breakpoint-sm: 768px;
$media-breakpoint-xs: 512px;
