*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
.container {
  position: relative;
  width: 100%;
  max-width: 1030px;
  padding: 0 1.25rem;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    main .container {
      padding: 0 2.5rem; } }
  header .container {
    display: flex;
    align-items: center;
    height: 100%; }
  .hero .container {
    display: flex;
    flex-direction: column; }

.row {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  @media screen and (min-width: 992px) {
    .row {
      flex-direction: row; } }

.column {
  position: relative;
  width: 100%;
  padding: 0 1rem; }

@media screen and (min-width: 992px) {
  .column-half {
    max-width: 50%; } }

@media screen and (min-width: 992px) {
  .column-eight {
    max-width: 66.66667%; } }

@media screen and (min-width: 992px) {
  .column-four {
    max-width: 33.33333%; } }

.button {
  position: relative;
  display: inline-block;
  height: 50px;
  padding: 0 2rem;
  font-size: 14px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .5px;
  cursor: pointer;
  background-color: #d72e63;
  border: none;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: background-color .2s ease-out; }
  .button:focus, .button:hover {
    color: #fff;
    background-color: #b0224e;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2); }

.form-group {
  width: 100%;
  margin-bottom: 1rem; }

.form-control {
  width: 100%;
  padding: .5rem 1rem;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: none;
  transition: all .3s ease; }
  .form-control:focus {
    border-color: rgba(14, 41, 51, 0.7);
    outline: none;
    box-shadow: 0 0 5px rgba(14, 41, 51, 0.7); }

.menu-toggler {
  padding: 0;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  border: none; }
  .menu-toggler:focus {
    outline: 0; }
  .menu-toggler:focus, .menu-toggler:hover {
    color: #ddd; }

.sidebar-mask {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100vh;
  cursor: pointer;
  background: #212121;
  opacity: .5; }
  @media screen and (max-width: 767px) {
    .sidebar-mask.visible {
      display: block;
      opacity: .5;
      transition: opacity .3s; } }

.sidebar {
  position: fixed;
  top: 0;
  left: -330px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  width: calc(100% - 64px);
  max-width: 330px;
  height: 100vh;
  padding: 0 2.5rem 2.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #0e2933;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transition: left .3s; }
  .sidebar.open {
    left: 0;
    visibility: visible; }
  @media screen and (min-width: 768px) {
    .sidebar {
      left: 0;
      padding: 2.5rem;
      visibility: visible; } }

.nav {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  margin: auto 0; }

.nav-link {
  position: relative;
  height: 40px;
  font-size: .8rem;
  font-weight: 700;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 1;
  transition: opacity .15s ease, transform .75s ease;
  transform: translateY(0); }
  .nav-link.active {
    color: #fff; }
    .nav-link.active::after {
      max-width: 100%; }
  .nav-link + .nav-link {
    margin-top: 1rem; }
  .nav-link:focus, .nav-link:hover {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    outline: 0; }
    .nav-link:focus::after, .nav-link:hover::after {
      max-width: 100%; }
  .nav-link::after, .nav-link::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2.5px;
    content: "";
    background-color: rgba(14, 41, 51, 0.3);
    border-radius: .2rem; }
  .nav-link::after {
    max-width: 0;
    background-image: linear-gradient(90deg, #0e2933, #eee); }
  @media screen and (min-width: 768px) {
    .loading .nav-link {
      opacity: 0;
      transform: translateY(1rem); }
    .nav-link:nth-child(1) {
      transition-delay: 0.45s; }
    .nav-link:nth-child(2) {
      transition-delay: 0.65s; }
    .nav-link:nth-child(3) {
      transition-delay: 0.85s; }
    .nav-link:nth-child(4) {
      transition-delay: 1.05s; }
    .nav-link:nth-child(5) {
      transition-delay: 1.25s; } }

.social {
  display: flex;
  align-self: flex-end;
  padding: 0;
  margin: 0;
  list-style: none; }

.social-item + .social-item {
  margin-left: 1rem; }

.social-link {
  display: block;
  color: rgba(255, 255, 255, 0.6); }
  .social-link:focus, .social-link:hover {
    color: #d72e63;
    text-decoration: none;
    outline: 0; }

.brand {
  display: flex;
  align-items: center;
  margin-right: auto;
  color: #fff;
  border: 1px solid transparent;
  outline: 0; }
  .brand:focus, .brand:hover {
    color: #d72e63;
    text-decoration: none; }
  .brand:focus {
    border-color: #d72e63; }
  .sidebar .brand {
    width: 100%;
    height: 64px;
    margin: 0; }

.brand-logo {
  margin-right: .5rem; }

.brand-description {
  margin-left: auto;
  text-align: right; }

.brand-name {
  margin: 0;
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase; }

.brand-title {
  margin: 0;
  font-size: .7rem;
  text-transform: uppercase; }

.brand-figure-wrapper {
  margin-bottom: 3rem; }
  @media screen and (min-width: 992px) {
    .brand-figure-wrapper {
      float: right;
      margin-bottom: 1rem;
      margin-left: 1rem; } }

.brand-figure {
  position: relative;
  max-width: 300px; }
  .brand-figure::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: 5px;
    box-shadow: inset 0 0 0 10px rgba(14, 41, 51, 0.4), 0 1px 2px rgba(14, 41, 51, 0.3); }

.brand-avatar {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: 100%;
  border-radius: 5px; }

.brand-address {
  padding: 1.25rem;
  color: #fff;
  background-color: #0e2933;
  border-radius: 3px; }
  .brand-address strong {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1.2rem; }

.brand-map-wrapper {
  margin-top: 3rem; }

.brand-map {
  min-height: 350px; }

@keyframes animateLinesX {
  0% {
    transform: translate(0, 0, 0); }
  50% {
    transform: translate(100%, 0, 0); }
  100% {
    transform: translate(0, 0, 0); } }

@keyframes animateLinesY {
  0% {
    transform: translate(0, 0, 0); }
  50% {
    transform: translate(0, 20%, 0); }
  100% {
    transform: translate(0, 0, 0); } }

.hero {
  position: relative;
  display: flex;
  min-height: calc(100vh - 64px);
  padding: 2.5rem 0 1.25rem;
  overflow-y: hidden;
  color: #fff;
  background-color: #0e2933; }
  .hero line {
    stroke: rgba(255, 255, 255, 0.1);
    stroke-width: 1px;
    transform: translate(0, 0, 0);
    will-change: transform; }
    .ready .hero line:nth-child(1), .ready .hero line:nth-child(2), .ready .hero line:nth-child(3), .ready .hero line:nth-child(4), .ready .hero line:nth-child(5), .ready .hero line:nth-child(6) {
      animation: animateLinesX 5s;
      animation-delay: 2s; }
  @media screen and (min-width: 768px) {
    .hero {
      min-height: 100vh; } }

.hero-lines {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.hero-intro {
  margin: auto 0; }
  @media screen and (min-width: 992px) {
    .hero-intro {
      max-width: 75%; } }

.hero-title {
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 700;
  opacity: 1;
  transition: transform .75s, opacity .3s;
  transition-delay: .45s;
  transform: translateY(0); }
  .loading .hero-title {
    opacity: 0;
    transform: translateY(-120%); }
  @media screen and (min-width: 768px) {
    .hero-title {
      font-size: 3rem; } }

.hero-strap {
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
  opacity: 1;
  transition: transform .75s, opacity .3s;
  transition-delay: .45s;
  transform: translateY(0); }
  .loading .hero-strap {
    opacity: 0;
    transform: translateY(120%); }
  @media screen and (min-width: 768px) {
    .hero-strap {
      font-size: 1.3rem; } }

.project {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 992px) {
    .project {
      flex-direction: row; } }

.project-timeline {
  position: relative;
  min-width: 130px;
  max-width: 130px; }
  @media screen and (min-width: 992px) {
    .project-timeline {
      padding: .8rem;
      border-right: 1px solid #ddd; }
      .project-timeline::before {
        position: absolute;
        top: 20px;
        right: -5px;
        display: block;
        width: 10px;
        height: 10px;
        content: "";
        background-color: #0e2933;
        border-radius: 50%; } }

.project-timeline-date {
  font-style: italic;
  color: #0e2933; }
  @media screen and (min-width: 992px) {
    .project-timeline-date {
      color: #666; } }

.project-content {
  position: relative;
  padding: 0; }
  @media screen and (min-width: 992px) {
    .project-content {
      padding: 0 1.5rem 2rem; } }

.project-title {
  font-size: 2rem;
  font-weight: 400;
  text-transform: none; }

.project-screenshot {
  display: block;
  max-width: 100%;
  margin: 1rem 0; }

.project-description {
  padding: 2rem 0 1rem; }

.project-tech {
  display: inline-block;
  padding: .1rem .3rem;
  margin-bottom: .5rem;
  font-size: .9rem;
  color: #333;
  background-color: #ddd;
  border-radius: 3px; }

.project-divider {
  height: 1px;
  margin-top: 2rem;
  background-color: #ddd;
  border: none; }

html,
body {
  min-height: 100vh; }

body {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  color: #444; }
  @media screen and (min-width: 768px) {
    body {
      padding-top: 0; } }

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 64px;
  background-color: #0e2933;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); }
  @media screen and (min-width: 768px) {
    header {
      display: none; } }

footer {
  padding: 0.625rem 0;
  margin-top: auto;
  background-color: #0e2933; }
  footer p {
    margin: 1rem 0;
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.6); }
  footer a {
    color: #fff; }
    footer a:focus, footer a:hover {
      color: rgba(255, 255, 255, 0.6); }
  @media screen and (min-width: 768px) {
    footer {
      margin-left: 330px; } }

main {
  flex: 1 auto; }
  @media screen and (min-width: 768px) {
    main {
      margin-left: 330px; } }

section {
  padding: 2.5rem 0; }

img {
  max-width: 100%; }

strong {
  font-weight: 500; }

.section-intro {
  margin-bottom: 1.25rem;
  font-size: 1.2rem;
  font-style: italic; }
  @media screen and (min-width: 1024px) {
    .section-intro {
      max-width: 70%; } }

h2:not(.hero-title) {
  position: relative;
  display: inline-block;
  margin-bottom: 1.25rem;
  font-size: 2.5rem;
  text-transform: uppercase; }
  h2:not(.hero-title)::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 3px;
    content: "";
    background-color: #0e2933;
    background-image: linear-gradient(to right, #0e2933, #d72e63); }

h3 {
  font-weight: 700; }

h4 {
  font-size: 1.5rem;
  font-weight: 700; }

a {
  color: #d72e63;
  transition: color .3s ease-in-out; }
  a:focus, a:hover {
    color: #b0224e;
    text-decoration: none; }

svg {
  vertical-align: middle; }

.ready.no-scroll {
  overflow: hidden; }

.anchorjs-link {
  transition: all .25s linear; }

*:hover > .anchorjs-link {
  margin-left: -1.125em !important; }
