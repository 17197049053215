@import "variables";
@import "reboot";
@import "grid";
@import "form";
@import "sidebar";
@import "brand";
@import "hero";
@import "projects";

html,
body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  padding-top: $header-height;
  color: $body-color;

  @media screen and (min-width: $media-breakpoint-sm) {
    padding-top: 0;
  }
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: $header-height;
  background-color: $dark-color;
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);

  @media screen and (min-width: $media-breakpoint-sm) {
    display: none;
  }
}

footer {
  padding: ($spacer / 2) 0;
  margin-top: auto;
  background-color: $dark-color;

  p {
    margin: 1rem 0;
    font-size: .9rem;
    color: transparentize(#fff, .4);
  }

  a {
    color: #fff;

    &:focus,
    &:hover {
      color: transparentize(#fff, .4);
    }
  }

  @media screen and (min-width: $media-breakpoint-sm) {
    margin-left: $sidebar-width;
  }
}

main {
  flex: 1 auto;

  @media screen and (min-width: $media-breakpoint-sm) {
    margin-left: $sidebar-width;
  }
}

section {
  padding: ($spacer * 2) 0;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 500;
}

.section-intro {
  margin-bottom: $spacer;
  font-size: 1.2rem;
  font-style: italic;

  @media screen and (min-width: $media-breakpoint-lg) {
    max-width: 70%;
  }
}


h2:not(.hero-title) {
  position: relative;
  display: inline-block;
  margin-bottom: $spacer;
  font-size: 2.5rem;
  text-transform: uppercase;

  &::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 3px;
    content: "";
    background-color: $primary-color;
    background-image: linear-gradient(to right, $primary-color, $accent-color);
  }
}

h3 {
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

a {
  color: $accent-color;
  transition: color .3s ease-in-out;

  &:focus,
  &:hover {
    color: darken($accent-color, 10);
    text-decoration: none;
  }
}

svg {
  vertical-align: middle;
}

.ready.no-scroll {
  overflow: hidden;
}

.anchorjs-link {
  transition: all .25s linear;
}
*:hover > .anchorjs-link {
  margin-left: -1.125em !important;
}